<template>
  <b-row>
    <b-col cols="12">
      <hdkt-table-basic></hdkt-table-basic>
    </b-col>
  </b-row>
</template>

<script>
import {BRow, BCol} from 'bootstrap-vue'
import HdktTableBasic from "../../../table/vue-good-table/HdktTableBasic";
export default {
  name: "Nksx",
  components: {
    HdktTableBasic,
    BRow, BCol
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>